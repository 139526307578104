import animate from 'ant-design-vue/es/_util/css-animation';
import './index.less';
import { isCpf, upperCamelCase, unobservable, isEmpty, isEmail, isWords } from "@/resources/util";
import timezone from "@/resources/timezone.js";
import EventMixin from "@/mixins/EventMixin.js";
import moment from 'moment';

export default {

    props: {

        visible: { type: Boolean }
    },
    mixins: [EventMixin],
    beforeCreate() {

        this.oldData;
        this.session = this.$store.getters['session/user'];
        this.loading = true;

        this.$store.dispatch('usuario/get', { id: this.session.user.id }).then(data => {

            data.data_nascimento = moment(data.data_nascimento) || null;
            this.oldData = JSON.parse(JSON.stringify(data));
            this.oldData.data_nascimento = data.data_nascimento
            this.data = data;

            if (this.data.avatar) {

                this.profilePhoto = data.avatar;
                this.foto = data.avatar;
            }
        }).finally(() => this.loading = false);

        this.timezones = unobservable(timezone.map(item => ({ label: item, key: item })));
    },
    watch: {

        'isPasswordVisible'(v) {

            if (!v) this.$refs.form.clearValidate(['senha_atual', 'nova_senha', 'confirmar_nova_senha']);

            this.rules.senha_atual.required = v;
            this.rules.nova_senha[0].required = v;
            this.rules.confirmar_nova_senha.required = v;
        }
    },
    data() {

        return {

            loading: false,
            isPasswordVisible: false,
            invalidCpf: false,
            profilePhoto: null,
            foto: null,
            passwordForce: 0,
            passwordStatus: 'exception',
            data: {

                nome: undefined,
                email: undefined,
                usuario: undefined,
                cpf: undefined,
                sexo: undefined,
                data_nascimento: null,
                telefone: undefined,
                timezone: undefined,
                senha_atual: '',
                nova_senha: '',
                confirmar_nova_senha: ''
            },
            rules: {

                nome: {

                    required: true,
                    validator: (rule, value, cb) => cb(!isEmpty(value) ? (isWords(value) ? undefined : `Por favor, informe um nome válido`) : `O nome é obrigatório.`)
                },
                email: {

                    required: true,
                    validator: (rule, value, cb) => cb(!isEmpty(value) ? (isEmail(value) ? undefined : `Por favor, informe um email válido`) : `O email é obrigatório`)
                },
                cpf: {

                    required: true,
                    validator: (rule, value, cb) => cb(!isEmpty(value) ? (isCpf(value) ? undefined : `Por favor, informe um CPF válido`) : `O CPF é obrigatório`)
                },
                sexo: {},
                data_nascimento: {

                    required: false
                },
                telefone: {},
                timezone: {},
                senha_atual: {

                    required: false,
                    message: 'A senha atual é obrigatória.'
                },
                nova_senha: [

                    {
                        required: false,
                        message: `A nova senha é obrigatória.`,
                    },
                    {
                        validator: (rule, value, cb) => {

                            if (!this.isPasswordVisible) return cb(undefined);
                            if (!value) value = '';

                            let tests = {

                                characters: {

                                    is: value.length >= 6 && value.length <= 20,
                                    message: 'no mínimo 6 e no máximo 20 caracteres',
                                    force: 15
                                },
                                lower: {

                                    is: /[a-z]/.test(value),
                                    message: 'no mínimo um caracter minúsculo',
                                    force: 10
                                },
                                upper: {

                                    is: /[A-Z]/.test(value),
                                    message: 'no mínimo um caracter maiúsculo',
                                    force: 25
                                },
                                number: {

                                    is: /\d/.test(value),
                                    message: 'um número',
                                    force: 25
                                },
                                special: {

                                    is: /\W/.test(value),
                                    message: 'um caracter especial (Ex: @#$,?&%!)',
                                    force: 25
                                }
                            };

                            let msg = `A senha deve conter `;
                            let parts = [];

                            let force = 0;

                            for (let k in tests) {

                                let test = tests[k];

                                if (test.is) {

                                    force += test.force;

                                } else {

                                    parts.push(test.message);
                                }
                            }

                            if (parts.length) {

                                if (parts.length > 1) parts[parts.length - 1] = `e ${parts[parts.length - 1]}`;
                                msg += parts.join(', ');
                            }

                            this.passwordForce = force;
                            this.passwordStatus = force <= 30 ? '#f5222d' : (force <= 60 ? 'rgb(255, 85, 0)' : (force < 100 ? '#1890ff' : 'rgb(135, 208, 104)'));

                            cb(this.data.senha_atual === value ? 'A nova senha não pode ser igual à anterior' : (parts.length ? msg : undefined));
                        }
                    }
                ],
                confirmar_nova_senha: {

                    required: false,
                    validator: (rule, value, cb) => {

                        if (!this.isPasswordVisible) return cb(undefined);
                        if (!value) return cb('A confirmação da senha é obrigatória');
                        cb(this.data.nova_senha !== value ? 'As senhas não coincidem' : undefined);
                    }
                }
            }
        }
    },
    methods: {

        getTransitionProps() {

            const transitionName = 'fade';

            return {

                on: {

                    enter: (el, done) => {

                        animate(el, `${transitionName}-enter`, done);
                    },
                    leave: (el, done) => {

                        animate(el, `${transitionName}-leave`, done);
                    }
                }
            };
        },
        dummyRequest({ file, onSuccess }) {

            setTimeout(() => {

                onSuccess("ok");

            }, 0);
        },
        disabledDate(current) {

            return current._d && current._d > moment().endOf('day');
        },
        handleCancel(e) {

            if(this.loading) return;
            this.$emit('cancel', e);
            this.$nextTick(() => {

                this.$refs.form.resetFields();
                Object.assign(this.data, this.oldData);
                this.profilePhoto = this.foto;
            });
        },
        handleShowChangePassword() {

            this.isPasswordVisible = !this.isPasswordVisible;
        },
        handleChange(info) {

            let loadPicture;

            if (info.file.status === "uploading") {

                const reader = new FileReader();

                loadPicture = new Promise((resolve, reject) => {

                    reader.onload = function () {

                        info.file.type.slice(0, 5) !== "image" ? reject() : resolve(this.result);
                    }

                }).then((data) => {

                    this.profilePhoto = null;

                    this.$nextTick(() => {

                        this.profilePhoto = data;
                    })

                }).catch(() => {

                    this.$message.error(`É permitido apenas imagens no formato: ".png, .jpeg, .jpg, .bmp, .jfif"`, 10);
                });

                reader.readAsDataURL(info.file.originFileObj);
            }
        },
        handleRemovePhoto(e) {

            e.stopPropagation();

            this.$confirm({

                title: 'Deseja realmente remover a sua foto de perfil?',
                okText: 'Sim',
                okType: 'danger',
                cancelText: 'Não',
                onOk: () => {

                    this.profilePhoto = null;
                }
            });
        },
        handleEnterSubmit(e) {

            if (e.keyCode === 13) this.handleSubmit(e);
        },
        handleSubmit(e) {

            e.preventDefault();

            this.$refs.form.validate(valid => {

                if (!valid) return;

                this.loading = true;

                let usuario = Object.assign({}, this.data);
                usuario = {

                    id: usuario.id,
                    cpf: usuario.cpf,
                    email: usuario.email,
                    username: usuario.username,
                    birth_date: usuario.data_nascimento ? moment(usuario.data_nascimento).format('YYYY-MM-DD') : null,
                    gender: usuario.sexo,
                    name: usuario.nome,
                    phone: usuario.telefone,
                    timezone: usuario.timezone
                };

                if (this.profilePhoto !== this.foto) {

                    usuario.avatar = this.profilePhoto;

                } else {

                    delete usuario.avatar;
                }

                if (this.data.nova_senha) {

                    usuario.password = this.data.senha_atual;
                    usuario.new_password = this.data.nova_senha;
                }

                this.$store.dispatch('usuario/saveProfile', usuario).then(data => {

                    data.affectedRows ? this.$message.success('Editado com sucesso') : this.$message.error('Não foi possível realizar a edição.');

                    this.$nextTick(() => {

                        const user = {

                            ... this.session.user,
                            avatar: data.avatar,
                            gender: usuario.gender,
                            name: usuario.name,
                            phone: usuario.phone
                        }

                        this.$store.dispatch("session/changeUser", {user});
                        this.$emit('cancel');
                    })

                }).catch(e => {

                    this.$message.error(e.message);

                }).finally(() => this.loading = false);
            })
        },
        handlerUserName() {

            if (!this.data.nome) return;
            this.data.nome = upperCamelCase(this.data.nome.replace(/\s{2,}/g, ' '));
        },
        filterTimezones(input, option) {

            return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_').toLowerCase().trim()) >= 0)
        }
    },
    render() {

        const formProps = {

            ref: "form",
            attrs: {

                id: 'form-perfil'
            },
            props: {

                model: this.data,
                rules: this.rules
            },
            nativeOn: {

                keydown: (e) => {

                    if (e.keyCode === 13) {

                        this.handleEnterSubmit(e);
                    }
                }
            }
        };

        let currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if(!this.data.timezone && currentTz) this.data.timezone = currentTz;

        return (

            <a-modal

                title="Perfil"
                visible={this.visible}
                okText="Salvar"
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                okButtonProps={{props: {disabled: this.loading}}}
                cancelButtonProps={{props: {disabled: this.loading}}}
            >
                <a-spin spinning={this.loading}>
                    <a-form-model

                        {...formProps}
                    >
                        <div id="inputFields">
                            <a-row class="profilePhoto">
                                <div id="photo">
                                    <transition name="slide-fade">
                                        {this.profilePhoto ?
                                            <img class="stylePhoto change-photo-transition" src={this.profilePhoto} /> :
                                            <g-icon class="change-photo-transition" id="empty-icon" icon={['fas', 'user']} />
                                        }
                                    </transition>
                                    <div id="photo-actions">
                                        <a-upload

                                            accept=".png, .jpeg, .jpg, .bmp, .jfif"
                                            beforeUpload={this.beforeUpload}
                                            customRequest={this.dummyRequest}
                                            onChange={this.handleChange}
                                            showUploadList={false}
                                        >
                                            <a-tooltip

                                                placement="top"
                                                title={this.profilePhoto ? 'Trocar' : 'Adicionar'}
                                            >
                                                <span>
                                                    <g-icon icon={['fas', this.profilePhoto ? 'edit' : 'plus']} />
                                                </span>
                                            </a-tooltip>
                                            <a-tooltip

                                                placement="top"
                                                title="Excluir"
                                                v-show={this.profilePhoto}
                                            >
                                                <span onClick={this.handleRemovePhoto}>
                                                    <g-icon icon={['fas', 'trash']} />
                                                </span>
                                            </a-tooltip>
                                        </a-upload>
                                    </div>
                                </div>
                            </a-row>
                            <a-row gutter={10}>
                                <a-col {...{ props: { xs: 24 } }}>
                                    <a-form-model-item

                                        label="Nome completo"
                                        prop="nome"
                                    >
                                        <a-input

                                            allowClear
                                            placeholder="Nome completo"
                                            maxLength={70}
                                            onBlur={this.handlerUserName}
                                            v-model={this.data.nome}
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col {...{ props: { xs: 24 } }}>
                                    <a-form-model-item

                                        label="Email"
                                        prop="email"
                                    >
                                        <a-input

                                            disabled
                                            allowClear
                                            placeholder="email@exemplo.com.br"
                                            maxLength={50}
                                            v-model={this.data.email}
                                        />
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                            <a-row gutter={10}>
                                <a-col {...{ props: { xs: 24, md: 12 } }}>
                                    <a-form-model-item

                                        label="Usuário"
                                        prop="usuario"
                                    >
                                        <a-input

                                            disabled
                                            v-model={this.data.usuario}
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col {...{ props: { xs: 24, md: 12 } }}>
                                    <a-form-model-item

                                        label="CPF"
                                        prop="cpf"
                                    >
                                        <a-input

                                            disabled
                                            allowClear
                                            placeholder="000.000.000-00"
                                            v-mask={'###.###.###-##'}
                                            v-model={this.data.cpf}
                                        />
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                            <a-row gutter={10}>
                                <a-col {...{ props: { xs: 24, md: 12 } }}>
                                    <a-form-model-item

                                        label="Sexo"
                                        prop="sexo"
                                    >
                                        <a-radio-group

                                            class="ant-radio-group-full"
                                            buttonStyle="solid"
                                            v-model={this.data.sexo}
                                        >
                                            <a-radio-button value="M">Masculino</a-radio-button>
                                            <a-radio-button value="F">Feminino</a-radio-button>
                                        </a-radio-group>
                                    </a-form-model-item>
                                </a-col>
                                <a-col {...{ props: { xs: 24, md: 12 } }}>
                                    <a-form-model-item

                                        label="Data de Nascimento"
                                        prop="data_nascimento"
                                    >
                                        <a-date-picker

                                            allowClear
                                            v-ant-picker-mask
                                            placeholder="Selecione a data"
                                            format="DD/MM/YYYY"
                                            showToday={false}
                                            disabledDate={this.disabledDate}
                                            v-model={this.data.data_nascimento}
                                        />
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                            <a-row gutter={10}>
                                <a-col {...{ props: { xs: 24, md: 12 } }}>
                                    <a-form-model-item

                                        label="Telefone"
                                        prop="telefone"
                                    >
                                        <a-input

                                            allowClear
                                            placeholder="(00) 0000-0000"
                                            v-mask={['(##) ####-####', '(##) #####-####']}
                                            v-model={this.data.telefone}
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col {...{ props: { xs: 24, md: 12 } }}>
                                    <a-form-model-item

                                        prop="timezone"
                                    >
                                        <span slot="label">
                                            Timezone&nbsp;
                                            <a-tooltip title={`Informe o timezone de sua região, caso contrário o padrão do navegador será utilizado (${currentTz})! Isso pode acarretar diferença nos horários registrados em diferentes fusos horários. Em caso de dúvida, entre em contato com o suporte.`}>
                                                <g-icon icon={["far", "question-circle"]} />
                                            </a-tooltip>
                                        </span>
                                        <a-select

                                            disabled
                                            allowClear
                                            showSearch
                                            placeholder="Selecione"
                                            filterOption={this.filterTimezones}
                                            options={this.timezones}
                                            v-model={this.data.timezone}
                                        />
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                            <a-row gutter={10}>
                                <a-col {...{ props: { xs: 24 } }}>
                                    <a-form-model-item>
                                        <a-switch

                                            checkedChildren="Alterar Senha"
                                            unCheckedChildren="Alterar Senha"
                                            checked={this.isPasswordVisible}
                                            onClick={this.handleShowChangePassword}
                                        />
                                    </a-form-model-item>
                                </a-col>
                                <a-col {...{ props: { xs: 24 } }}>
                                    <transition {...this.getTransitionProps()}>
                                        <div class="change-password" v-show={this.isPasswordVisible}>
                                            <a-form-model-item

                                                label="Senha atual"
                                                prop="senha_atual"
                                            >
                                                <a-input-password

                                                    allowClear
                                                    autocomplete="off"
                                                    maxLength={20}
                                                    v-model={this.data.senha_atual}
                                                />
                                            </a-form-model-item>
                                            <a-form-model-item

                                                label="Nova senha"
                                                prop="nova_senha"
                                            >
                                                <a-input-password

                                                    allowClear
                                                    autocomplete="off"
                                                    maxLength={20}
                                                    v-model={this.data.nova_senha}
                                                />
                                                <a-progress

                                                    percent={this.passwordForce}
                                                    strokeColor={this.passwordStatus}
                                                    style="height: 2px;"
                                                    v-show={this.data.nova_senha}
                                                />
                                            </a-form-model-item>
                                            <a-form-model-item

                                                label="Confirme a nova senha"
                                                prop="confirmar_nova_senha"
                                            >
                                                <a-input-password

                                                    allowClear
                                                    autocomplete="off"
                                                    maxLength={20}
                                                    v-model={this.data.confirmar_nova_senha}
                                                />
                                            </a-form-model-item>
                                        </div>
                                    </transition>
                                </a-col>
                            </a-row>
                        </div>
                    </a-form-model>
                </a-spin>
            </a-modal>
        )
    }
}
